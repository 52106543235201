import "waypoints/lib/jquery.waypoints";

import "./index.scss";

// init on document ready
$(function () {

  init();

  window.addEventListener('resize', function () {
    globalResizeHandler();
  }, true);
});

function init() {
  initScrollAnimations();
  globalResizeHandler();
  JumpNav.init();
}

function globalResizeHandler() {
  var breakpoints = { lapStart: 481, deskStart: 1025 };

  if (window.matchMedia("(min-width:" + breakpoints.deskStart + "px)").matches) {
    EqualHeight.makeEqual();
  }
  else if (window.matchMedia("(min-width:" + breakpoints.lapStart + "px)").matches) {
    EqualHeight.reset();
  }
  else {
    EqualHeight.reset();
  }
}

function initScrollAnimations() {
  $(".not-in-view").waypoint(function () {
    var element = this.element;
    $(element).removeClass("not-in-view");
  });
}

//jump nav
var JumpNav = (function () {
  var _allNavPointsLength = $(document).find("[data-jump-nav]").length
    , _currentNavNum = 0
    , _prevButton = $('.jumpNav__prev')
    , _nextButton = $('.jumpNav__next')
    ;

  function init() {
    updateButtons();
    addEvents();
  }

  function next() {
    _currentNavNum++;

    animateBody();
    updateButtons();
  }

  function prev() {
    _currentNavNum = 0;
    $('html, body').animate({
      scrollTop: 0
    }, 300);
    //animateBody();    
    //updateButtons();    
  }

  function addEvents() {
    _nextButton.on("click", next);
    _prevButton.on("click", prev);
    $(document).find("[data-jump-nav]").waypoint(function (direction) {
      var currNum = parseInt(this.element.dataset.jumpNav);
      updateNum(currNum);
    }, {
      offset: 0
    })
  }

  function updateNum(num) {
    _currentNavNum = num;
    updateButtons();
  }

  function updateButtons() {
    //        _prevButton.removeClass("jumpNav__control--inactive");
    //        _nextButton.removeClass("jumpNav__control--inactive");          
    //        
    //        if( _currentNavNum <= 0 ) {
    //            _prevButton.addClass("jumpNav__control--inactive");
    //            _nextButton.removeClass("jumpNav__control--inactive");           
    //        } 
    //        else if ( _currentNavNum >= _allNavPointsLength - 1 ) {
    //            _prevButton.removeClass("jumpNav__control--inactive");
    //            _nextButton.addClass("jumpNav__control--inactive"); 
    //        }    

  }

  function animateBody() {
    if (_currentNavNum >= _allNavPointsLength - 1) {
      _currentNavNum = _allNavPointsLength - 1;
    }
    if (_currentNavNum <= 0) {
      _currentNavNum = 0;
    }

    if (_currentNavNum >= 0 && _currentNavNum < _allNavPointsLength) {
      $('html, body').animate({
        scrollTop: $("[data-jump-nav=" + _currentNavNum + "]").offset().top
      }, 300);
    }

  }

  return {
    init: init
  };

})();

// module to create equal height containers
// with class js-grid-equalizer 
var EqualHeight = (function () {
  var _allEquals = $(".js-grid-equalizer");


  function reset() {
    _allEquals.each(function () {
      $(this).css({ height: "auto" });
    });
  }

  function makeEqual() {
    _allEquals.each(function () {
      var currHeight = $(this).next(".grid__item").height();
      $(this).css({ height: currHeight });
    });
  }

  return {
    makeEqual: makeEqual,
    reset: reset
  };

})();
